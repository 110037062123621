import React from 'react';
import { connect } from 'react-redux';
import { getOrder, allOrderImagesByPhase, storeEditorOrderImages, assignUserToOrder, getOrderImagesPath, storeOrderImagesInDB, allowSave, disableSave } from 'redux/actions/order-actions';
import { updateSettingsData, showLoader } from 'redux/actions/settings-actions';
import DownloadFile from 'components/ThemeComponents/downloadFile';
// import ImagesList from '../ImagesList';
import FileUpload from "components/Upload/FileUpload";
import ImagePreview from "components/Upload/ImagePreview";
import { hasRole, s3StaticPath, dateFormatMy, uuid } from 'helper/helperFunctions';
import {
	OverlayTrigger,
	Popover,
	Tooltip,
} from "react-bootstrap";
import { toast, Slide, Rotate, Flip } from 'react-toastify';
import {BiLoaderAlt} from 'react-icons/bi';
import AWS from 'aws-sdk';
import EXIF from 'exif-js';
import { Card } from "components/Card/Card.jsx";
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';

import Axios from 'axios';
import { getAgencyResourceByType } from '../../../../redux/actions/agency-resource-actions';
import OrderComments from 'components/Admin/Order/OrderComments';
import { history } from 'helper/history';
import { orderService } from 'services/order';
import { baseUrl } from '../../../../helper/ApiCall';
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';

// const AWS = require('aws-sdk');
var cloneDeep = require('lodash.clonedeep');

const Button = (props) => {
    return (
        <div>
            <p>Please wait... Images are being uploaded!</p>
            {
                props.showCancelButton &&
                <button onClick={props.cancelButton} type="button">Cancel</button>
            }
        </div>
    )
}
class EditorPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			workingImages: [],
			copyOfWorkingImages: [],
			allowedFormatsStringMime: 'image/jpeg',
			allowedFormatsStringExt: [".jpg"],
			collapseImageUploaderAndViewer: false,
			animated_logo: {},
			workingImagesCount: 0,
			orignalImagesCount: 0,
            uploadedFiles: 0,
            imageData: [],
			responseArray: [],
			currentImageName: '',
            allowSave: false,
            showCancelButton: true

		};
        this.props.getOrderImagesPathCall(this.props.orderId, 'working')

		this.toastIdImg = '';
		this.processedFiles = [];
	};

    cancelButton = () => {
        window.location.reload(false);
    }

	componentDidMount() {
		this.props.allOrderImagesByPhaseCall(this.props.orderId, 'original', this.props.tabName);
		orderService.getCountOfImagesByFolder(this.props.orderId, 'working').then(response => {
			this.setState({ workingImagesCount: response.data.count });
		});
		orderService.getCountOfImagesByFolder(this.props.orderId, 'original').then(response => {
			this.setState({ orignalImagesCount: response.data.count });
		});
	}

	setResourceFile = (key, value) => {
		const CancelToken = Axios.CancelToken;
		const source = CancelToken.source();
		value.axiosToken = source;
		this.setState((prevState) => {
			return {
				workingImages: [
					...prevState.workingImages,
					value
				]
			}
		});

	}

	updateResourceFile = (index, fileObject) => {
		const workingImagesState = cloneDeep(this.state.workingImages);
		const CancelToken = Axios.CancelToken;
		const source = CancelToken.source();
		fileObject.axiosToken = source;
		workingImagesState[index] = fileObject;
		this.setState({
			workingImages: workingImagesState,
		});
	}


	removeFile = (key, index) => {
		const workingImagesState = [...this.state.workingImages]
		workingImagesState.splice(index, 1);
		this.setState({
			workingImages: workingImagesState,
		});
	}
	clearAllTempImages = () => {
		this.setState({
			workingImages: [],
		});
	}

	// putFileToS3 = () => {

    //     // At position 0, remove 1 items:
	// 	arr = [...this.state.workingImages];
	// 	arr.splice(0, 1);
	// 	this.setState({
	// 		workingImages: arr,
	// 	});
	// }

	wait = ms => {return new Promise(resolve => setTimeout(resolve, ms))}
	uploadFiles = async () => {
        // this.props.showLoaderCall(true);
		var delay = 500;
		if(this.state.copyOfWorkingImages.length % 20 === 0 && this.state.copyOfWorkingImages.length > 0) {
			delay = 5000;
		}

		let self = this;
		//  create a loop function
		setTimeout(async() => {   //  call a delay seconds setTimeout when the loop is called


			let workingImages = [...this.state.workingImages];

			let arr = [...this.state.copyOfWorkingImages];

			if(arr) {
				// At position 0, get 1 items:
				let file_slice = arr.slice(0, 1);
				var file = file_slice[0];
				// At position 0, remove 1 item:
				arr.splice(0, 1);
				this.setState({
					copyOfWorkingImages: arr,
				});
			}

			if(file == undefined || !file || !file.name || !arr) {
				return;
			}

			let u_id = uuid().toString();
			let ext =  file.name.split('.').pop();
			var currentImageName = u_id + '.' + ext;
			EXIF.getData(file, function() {
				const exifData = EXIF.pretty(this);
				let Data = {};
				if (exifData) {
					// console.log("if u_id + '.' + ext===> file.name", file.name, 'original_file_name', u_id + '.' + ext);
					Data = {
						file_size : file.size,
						file_title : file.name,
						file_type : file.type,
						original_file_name : u_id + '.' + ext,
						exif_orientation : EXIF.getTag(this, "Orientation") ? EXIF.getTag(this, "Orientation") : null,
						use_in_highlight_video  : EXIF.getTag(this, "use_in_highlight_video") ? EXIF.getTag(this, "use_in_highlight_video") : 0,
						exif_date_taken : EXIF.getTag(this, "DateTime") ? EXIF.getTag(this, "DateTime") : null,
						DateTimeOriginal : EXIF.getTag(this, "DateTimeOriginal") ? EXIF.getTag(this, "DateTimeOriginal") : null,
						ModifyDate : EXIF.getTag(this, "ModifyDate") ? EXIF.getTag(this, "ModifyDate") : null,
						exif_manufacturer : EXIF.getTag(this, "Make") ? EXIF.getTag(this, "Make") : null,
						exif_camera_model : EXIF.getTag(this, "Model") ? EXIF.getTag(this, "Model") : null,
						exif_long   : EXIF.getTag(this, "GPSLongitude") ? EXIF.getTag(this, "GPSLongitude") : null,
						exif_lat : EXIF.getTag(this, "GPSLatitude") ? EXIF.getTag(this, "GPSLatitude") : null,
						height : EXIF.getTag(this, "PixelXDimension") ? EXIF.getTag(this, "PixelXDimension") : null,
						width  : EXIF.getTag(this, "PixelYDimension") ? EXIF.getTag(this, "PixelYDimension") : null,
					};
				} else {
					Data = {
						file_size : file.size,
						file_title : file.name,
						file_type : file.type,
						original_file_name : u_id + '.' + ext
					};
					//   console.log("No EXIF data found in image '" + file.name + "'.", 'original_file_name : ', u_id + '.' + ext);
				}

				if(Data) {
					let tmp_elm = [...self.state.imageData];
					tmp_elm.push(Data);
					self.setState({
						imageData : tmp_elm,
					},() => {
						// console.log("success", self.state.imageData)
					});
				}
			});

			try{

				const { urls } = await this.getPresignedUrls(currentImageName, file);

				setTimeout(async() => {
					const fileUrl = urls.find(url => url.orgfile_name === file.name).url;
					console.log("File URL: " , fileUrl);
					
					const response = await fetch(fileUrl, {
						method: 'PUT',
						body: file,
						headers: { 
							'Content-Type': file.type,
						},
					});

					if (response.ok) {
						// myBucket.putObject(params, (err, data)=>{


							// if (err) {
							// 	console.log('putObject err occored ', file.name, ' ', this.state.uploadedFiles, err, 'myBucket.putObject err.stack ', err.stack); // an error occurred
							// 	//return;
							// }
							// else {
							// 	console.log('myBucket.putObject response ', file.name, ' ', this.state.uploadedFiles);           // successful response
							// }

							const uploaded = this.state.uploadedFiles;
							this.setState({
								uploadedFiles: uploaded + 1
							},()=>{
									if (self.state.uploadedFiles === workingImages.length) {
										this.setState({ showCancelButton: false})
										toast.dismiss(self.toastIdImg);
										setTimeout(() => {

											const imageData = {images: self.state.imageData , uploaded_by_first_name: self.state.firstName, uploaded_by_last_name: self.state.lastName} ;
											self.props.storeOrderImagesInDBCall(imageData, self.props.orderId, 'working').then(

													response => {
														self.setState({
																workingImages: [],
																imageData: [],
																responseArray: [],
																uploadedFiles: 0,
																collapseImageUploaderAndViewer: false,
																firstName: '',
																lastName: '',
																allowSave: false,
																showCancelButton: true
															})
															this.props.disableSaveCall(false);
															orderService.getCountOfImagesByFolder(self.props.orderId, 'working').then(response => {
																self.setState({ workingImagesCount: response.data.count });
															});
														self.props.allOrderImagesByPhaseCall(self.props.orderId, 'working', self.props.tabName);
														// self.props.showLoaderCall(true);
													},
													error => {
														console.log('error');
													}
													)

										}, 1000);
									}
									else {
										toast.update(self.toastIdImg, {
											render: <React.Fragment>Images are Uploading. Please do not leave this page! {self.state.uploadedFiles +'/'+ workingImages.length}  <BiLoaderAlt className="loadingSpinner" /></React.Fragment>,
											progress: workingImages.length,
											type: toast.TYPE.INFO,
											position: "bottom-right",
										});
									}
							})

						// });//.then((response) => {console.log('myBucket.putObject response',response);});
					}
				}, delay);

			} catch (err) {
				console.log('S3 put object error', err);
				Object.keys(err).map(x => {
					file['message'] = err[x];
				});
			}



			if (this.state.uploadedFiles < workingImages.length) {           //  if the counter < 10, call the loop function
				this.uploadFiles();             //  ..  again which will trigger another
			}

		}, delay)
	}

    //  set your counter to 1
	// uploadFiles = async (myBucket, S3_BUCKET) => {
    //     // this.props.showLoaderCall(true);
	// 	var delay = 500;
	// 	if(this.state.copyOfWorkingImages.length % 20 === 0 && this.state.copyOfWorkingImages.length > 0) {
	// 		delay = 5000;
	// 	}

	// 	let self = this;
	// 	//  create a loop function
	// 	setTimeout(() => {   //  call a delay seconds setTimeout when the loop is called


	// 		let workingImages = [...this.state.workingImages];

	// 		let arr = [...this.state.copyOfWorkingImages];

	// 		if(arr) {
	// 			// At position 0, get 1 items:
	// 			let file_slice = arr.slice(0, 1);
	// 			var file = file_slice[0];
	// 			// At position 0, remove 1 item:
	// 			arr.splice(0, 1);
	// 			this.setState({
	// 				copyOfWorkingImages: arr,
	// 			});
	// 		}

	// 		if(file == undefined || !file || !file.name || !arr) {
	// 			return;
	// 		}

	// 		let u_id = uuid().toString();
	// 		let ext =  file.name.split('.').pop();
	// 		var currentImageName = u_id + '.' + ext;
	// 		EXIF.getData(file, function() {
	// 			const exifData = EXIF.pretty(this);
	// 			let Data = {};
	// 			if (exifData) {
	// 				// console.log("if u_id + '.' + ext===> file.name", file.name, 'original_file_name', u_id + '.' + ext);
	// 				Data = {
	// 					file_size : file.size,
	// 					file_title : file.name,
	// 					file_type : file.type,
	// 					original_file_name : u_id + '.' + ext,
	// 					exif_orientation : EXIF.getTag(this, "Orientation") ? EXIF.getTag(this, "Orientation") : null,
	// 					use_in_highlight_video  : EXIF.getTag(this, "use_in_highlight_video") ? EXIF.getTag(this, "use_in_highlight_video") : 0,
	// 					exif_date_taken : EXIF.getTag(this, "DateTime") ? EXIF.getTag(this, "DateTime") : null,
	// 					DateTimeOriginal : EXIF.getTag(this, "DateTimeOriginal") ? EXIF.getTag(this, "DateTimeOriginal") : null,
	// 					ModifyDate : EXIF.getTag(this, "ModifyDate") ? EXIF.getTag(this, "ModifyDate") : null,
	// 					exif_manufacturer : EXIF.getTag(this, "Make") ? EXIF.getTag(this, "Make") : null,
	// 					exif_camera_model : EXIF.getTag(this, "Model") ? EXIF.getTag(this, "Model") : null,
	// 					exif_long   : EXIF.getTag(this, "GPSLongitude") ? EXIF.getTag(this, "GPSLongitude") : null,
	// 					exif_lat : EXIF.getTag(this, "GPSLatitude") ? EXIF.getTag(this, "GPSLatitude") : null,
	// 					height : EXIF.getTag(this, "PixelXDimension") ? EXIF.getTag(this, "PixelXDimension") : null,
	// 					width  : EXIF.getTag(this, "PixelYDimension") ? EXIF.getTag(this, "PixelYDimension") : null,
	// 				};
	// 			} else {
	// 				Data = {
	// 					file_size : file.size,
	// 					file_title : file.name,
	// 					file_type : file.type,
	// 					original_file_name : u_id + '.' + ext
	// 				};
	// 				//   console.log("No EXIF data found in image '" + file.name + "'.", 'original_file_name : ', u_id + '.' + ext);
	// 			}

	// 			if(Data) {
	// 				let tmp_elm = [...self.state.imageData];
	// 				tmp_elm.push(Data);
	// 				self.setState({
	// 					imageData : tmp_elm,
	// 				},() => {
	// 					// console.log("success", self.state.imageData)
	// 				});
	// 			}
	// 		});

	// 		try{
	// 			const params = {
	// 				ACL: 'public-read',
	// 				Body: file,
	// 				Bucket: S3_BUCKET,
	// 				Key: currentImageName
	// 			};

	// 			// var params = {Bucket: 'bucket', Key: 'key', Body: stream};
	// 			// s3.upload(params, function(err, data) {
	// 			// 	console.log(err, data);
	// 			// });


	// 			// if(this.state.uploadedFiles % 20 === 0 && this.state.uploadedFiles != 0) {
	// 			// 	await this.wait(3000);
	// 			// }
	// 			// await this.wait(300);

	// 			setTimeout(() => {
	// 				myBucket.putObject(params, (err, data)=>{


	// 					if (err) {
	// 						console.log('putObject err occored ', file.name, ' ', this.state.uploadedFiles, err, 'myBucket.putObject err.stack ', err.stack); // an error occurred
	// 						//return;
	// 					}
	// 					else {
	// 						console.log('myBucket.putObject response ', file.name, ' ', this.state.uploadedFiles);           // successful response
	// 					}

	// 					const uploaded = this.state.uploadedFiles;
	// 					this.setState({
	// 						uploadedFiles: uploaded + 1
	// 					},()=>{
	// 							if (self.state.uploadedFiles === workingImages.length) {
    //                                 this.setState({ showCancelButton: false})
	// 								toast.dismiss(self.toastIdImg);
	// 								setTimeout(() => {

	// 									const imageData = {images: self.state.imageData , uploaded_by_first_name: self.state.firstName, uploaded_by_last_name: self.state.lastName} ;
	// 									self.props.storeOrderImagesInDBCall(imageData, self.props.orderId, 'working').then(

	// 											response => {
	// 												self.setState({
	// 														workingImages: [],
	// 														imageData: [],
	// 														responseArray: [],
	// 														uploadedFiles: 0,
	// 														collapseImageUploaderAndViewer: false,
	// 														firstName: '',
	// 														lastName: '',
    //                                                         allowSave: false,
    //                                                         showCancelButton: true
	// 													})
    //                                                     this.props.disableSaveCall(false);
	// 													orderService.getCountOfImagesByFolder(self.props.orderId, 'working').then(response => {
	// 														self.setState({ workingImagesCount: response.data.count });
	// 													});
	// 												self.props.allOrderImagesByPhaseCall(self.props.orderId, 'working', self.props.tabName);
	// 												// self.props.showLoaderCall(true);
	// 											},
	// 											error => {
	// 												console.log('error');
	// 											}
	// 											)

	// 								}, 1000);
	// 							}
	// 							else {
	// 								toast.update(self.toastIdImg, {
	// 									render: <React.Fragment>Images are Uploading. Please do not leave this page! {self.state.uploadedFiles +'/'+ workingImages.length}  <BiLoaderAlt className="loadingSpinner" /></React.Fragment>,
	// 									progress: workingImages.length,
	// 									type: toast.TYPE.INFO,
	// 									position: "bottom-right",
	// 								});
	// 							}
	// 					})

	// 				});//.then((response) => {console.log('myBucket.putObject response',response);});
	// 			}, delay);

	// 		} catch (err) {
	// 			console.log('S3 put object error', err);
	// 			Object.keys(err).map(x => {
	// 				file['message'] = err[x];
	// 			});
	// 		}



	// 		if (this.state.uploadedFiles < workingImages.length) {           //  if the counter < 10, call the loop function
	// 			this.uploadFiles(myBucket, S3_BUCKET);             //  ..  again which will trigger another
	// 		}                       //  ..  setTimeout()
	// 		// else {
	// 		// 	//Put to DB
	// 		// 	this.setState({
	// 		// 		workingImages: [],
	// 		// 		imageData: [],
	// 		// 		responseArray: [],
	// 		// 		uploadedFiles: 0,
	// 		// 		collapseImageUploaderAndViewer: false,
	// 		// 		firstName: '',
	// 		// 		lastName: '',
	// 		// 	})
	// 		// 	// console.log('this.state.workingImages', this.state.workingImages.length, 'this.state.uploadedFiles', this.state.uploadedFiles);
	// 		// }

	// 	}, delay)
	// }

    //  set your counter to 1
	// uploadFiles_new_copy = (myBucket, S3_BUCKET) => {
	// 	var delay = 500;
	// 	if(this.state.uploadedFiles % 25 === 0 && this.state.uploadedFiles > 0) {
	// 		delay = 3000;
	// 	}

	// 	let self = this;
	// 	//  create a loop function
	// 	setTimeout(() => {   //  call a delay seconds setTimeout when the loop is called


	// 		let workingImages = [...this.state.workingImages];

	// 		let arr = [...this.state.copyOfWorkingImages];

	// 		if(arr) {
	// 			// At position 0, get 1 items:
	// 			let file_slice = arr.slice(0, 1);
	// 			var file = file_slice[0];
	// 			// At position 0, remove 1 item:
	// 			arr.splice(0, 1);
	// 			this.setState({
	// 				copyOfWorkingImages: arr,
	// 			});
	// 		}

	// 		if(file == undefined || !file || !file.name || !arr) {
	// 			return;
	// 		}

	// 		let u_id = uuid().toString();
	// 		let ext =  file.name.split('.').pop();
	// 		var currentImageName = u_id + '.' + ext;
	// 		EXIF.getData(file, function() {
	// 			const exifData = EXIF.pretty(this);
	// 			let Data = {};
	// 			if (exifData) {
	// 				console.log("if u_id + '.' + ext===> file.name", file.name, 'original_file_name', u_id + '.' + ext);
	// 				Data = {
	// 					file_size : file.size,
	// 					file_title : file.name,
	// 					file_type : file.type,
	// 					original_file_name : u_id + '.' + ext,
	// 					exif_orientation : EXIF.getTag(this, "Orientation") ? EXIF.getTag(this, "Orientation") : null,
	// 					use_in_highlight_video  : EXIF.getTag(this, "use_in_highlight_video") ? EXIF.getTag(this, "use_in_highlight_video") : 0,
	// 					exif_date_taken : EXIF.getTag(this, "DateTime") ? EXIF.getTag(this, "DateTime") : null,
	// 					DateTimeOriginal : EXIF.getTag(this, "DateTimeOriginal") ? EXIF.getTag(this, "DateTimeOriginal") : null,
	// 					ModifyDate : EXIF.getTag(this, "ModifyDate") ? EXIF.getTag(this, "ModifyDate") : null,
	// 					exif_manufacturer : EXIF.getTag(this, "Make") ? EXIF.getTag(this, "Make") : null,
	// 					exif_camera_model : EXIF.getTag(this, "Model") ? EXIF.getTag(this, "Model") : null,
	// 					exif_long   : EXIF.getTag(this, "GPSLongitude") ? EXIF.getTag(this, "GPSLongitude") : null,
	// 					exif_lat : EXIF.getTag(this, "GPSLatitude") ? EXIF.getTag(this, "GPSLatitude") : null,
	// 					height : EXIF.getTag(this, "PixelXDimension") ? EXIF.getTag(this, "PixelXDimension") : null,
	// 					width  : EXIF.getTag(this, "PixelYDimension") ? EXIF.getTag(this, "PixelYDimension") : null,
	// 				};
	// 			} else {
	// 				Data = {
	// 					file_size : file.size,
	// 					file_title : file.name,
	// 					file_type : file.type,
	// 					original_file_name : u_id + '.' + ext
	// 				};
	// 				  console.log("No EXIF data found in image '" + file.name + "'.", 'original_file_name : ', u_id + '.' + ext);
	// 			}

	// 			if(Data) {
	// 				let tmp_elm = [...self.state.imageData];
	// 				tmp_elm.push(Data);
	// 				self.setState({
	// 					imageData : tmp_elm,
	// 				},() => {
	// 					console.log("success", self.state.imageData)
	// 				});
	// 			}
	// 		});

	// 		try{
	// 			// Use S3 ManagedUpload class as it supports multipart uploads
	// 			let upload = myBucket.ManagedUpload({
	// 				params: {
	// 					ACL: 'public-read',
	// 					Bucket: S3_BUCKET,
	// 					Key: currentImageName,
	// 					Body: file
	// 				}
	// 			});

	// 			let promise = upload.promise();

	// 			promise.then(
	// 				function(data) {
	// 					alert("Successfully uploaded photo.");

	// 					const uploaded = this.state.uploadedFiles;
	// 					this.setState({
	// 						uploadedFiles: uploaded + 1
	// 					},()=>{
	// 							if (self.state.uploadedFiles === workingImages.length) {
	// 								toast.dismiss(self.toastIdImg);
	// 								setTimeout(() => {

	// 									const imageData = {images: self.state.imageData , uploaded_by_first_name: self.state.firstName, uploaded_by_last_name: self.state.lastName} ;
	// 									self.props.storeOrderImagesInDBCall(imageData, self.props.orderId, 'working').then(

	// 											response => {
	// 												self.setState({
	// 														workingImages: [],
	// 														imageData: [],
	// 														responseArray: [],
	// 														uploadedFiles: 0,
	// 														collapseImageUploaderAndViewer: false,
	// 														firstName: '',
	// 														lastName: '',
	// 													})
	// 													orderService.getCountOfImagesByFolder(self.props.orderId, 'working').then(response => {
	// 														self.setState({ workingImagesCount: response.data.count });
	// 													});
	// 												self.props.allOrderImagesByPhaseCall(self.props.orderId, 'working', self.props.tabName);
	// 												self.props.showLoaderCall(true);
	// 											},
	// 											error => {
	// 												console.log('error');
	// 											}
	// 											)

	// 								}, 1000);
	// 							}
	// 							else {
	// 								toast.update(self.toastIdImg, {
	// 									render: <React.Fragment>Images are Uploading. Please wait! {self.state.uploadedFiles +'/'+ workingImages.length}  <BiLoaderAlt className="loadingSpinner" /></React.Fragment>,
	// 									progress: workingImages.length,
	// 									type: toast.TYPE.INFO,
	// 									position: "bottom-right",
	// 								});
	// 							}
	// 					})

	// 					if (this.state.uploadedFiles < workingImages.length) {           //  if the counter < 10, call the loop function
	// 						this.uploadFiles(myBucket, S3_BUCKET);             //  ..  again which will trigger another
	// 					}
	// 				},
	// 				function(err) {
	// 					return alert("There was an error uploading your photo: ", err.message);
	// 				}
	// 			);

	// 		} catch (err) {
	// 			console.log('S3 put object error', err);
	// 			Object.keys(err).map(x => {
	// 				file['message'] = err[x];
	// 			});
	// 		}



	// 		// if (this.state.uploadedFiles < workingImages.length) {           //  if the counter < 10, call the loop function
	// 		// 	this.uploadFiles(myBucket, S3_BUCKET);             //  ..  again which will trigger another
	// 		// }                       //  ..  setTimeout()
	// 		// else {
	// 		// 	//Put to DB
	// 		// 	this.setState({
	// 		// 		workingImages: [],
	// 		// 		imageData: [],
	// 		// 		responseArray: [],
	// 		// 		uploadedFiles: 0,
	// 		// 		collapseImageUploaderAndViewer: false,
	// 		// 		firstName: '',
	// 		// 		lastName: '',
	// 		// 	})
	// 		// 	// console.log('this.state.workingImages', this.state.workingImages.length, 'this.state.uploadedFiles', this.state.uploadedFiles);
	// 		// }

	// 	}, delay)
	// }

	confirmResourceDelete = () =>{
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete description='Are you sure you want to delete all Previous Edited Images?' typeName='Previous Edited Images' onClosePro={onClose} deleteType={this.saveImages} />
        })
    }

	
	getPresignedUrls = async (currentImageName, file) => {
        const { orderImagePath } = this.props;
        let AdminUser = JSON.parse(localStorage.getItem('user'));
        const files = [
            {
                name: orderImagePath + '/' + currentImageName, // Concatenates file name with orderImagePath
                orgfile_name: file.name, // Concatenates file name with orderImagePath
                type: file.type,
            }
        ]
        const response = await fetch(`${baseUrl}generate-presigned-urls-working`, {
            method: 'POST',
            body: JSON.stringify({
                files
            }),
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AdminUser.token  
            },
        });
        return response.json();
    };

	
	saveImages = () => {
		
        this.setState({
            allowSave: true
        })
        this.props.allowSaveCall(true);
		this.props.showLoaderCall(false);
		const workingImages = [...this.state.workingImages];

		let temp_files = [...this.state.workingImages];
		this.setState({
			copyOfWorkingImages: temp_files
		});

		this.toastIdImg = null;
			this.toastIdImg =  toast(<React.Fragment>Images are Uploading. Please do not leave this page! {this.state.uploadedFiles +'/'+ workingImages.length}  <BiLoaderAlt className="loadingSpinner" /></React.Fragment>, {
				progress: workingImages.length,
				type: toast.TYPE.INFO,
				position: "bottom-right",
                autoClose: true
			});

		try{
			// const { urls } = await this.getPresignedUrls(currentImageName, file.type);
		} catch (err) {
			console.log(err);
		}

		this.uploadFiles();                   //  start the loop
    }

	// saveImages1 = () => {
		
    //     this.setState({
    //         allowSave: true
    //     })
    //     this.props.allowSaveCall(true);
	// 	this.props.showLoaderCall(false);
	// 	const workingImages = [...this.state.workingImages];

	// 	let temp_files = [...this.state.workingImages];
	// 	this.setState({
	// 		copyOfWorkingImages: temp_files
	// 	});


    //     // const files = [...this.state.workingImages];
    //     // const total_files = files.length;
    //     const orderImagePath = this.props.orderImagePath;
	// 	this.toastIdImg = null;
	// 		this.toastIdImg =  toast(<React.Fragment>Images are Uploading. Please do not leave this page! {this.state.uploadedFiles +'/'+ workingImages.length}  <BiLoaderAlt className="loadingSpinner" /></React.Fragment>, {
	// 			progress: workingImages.length,
	// 			type: toast.TYPE.INFO,
	// 			position: "bottom-right",
    //             autoClose: true
	// 		});

    //     // let responseArray = [];
    //     // var currentImageName = '';

	// 	try{
	// 		const { REACT_APP_AWS_ACCESS_KEY_ID, REACT_APP_AWS_EDITED_BUCKET, REACT_APP_AWS_DEFAULT_REGION, REACT_APP_AWS_SECRET_ACCESS_KEY  } = process.env;
	// 		var S3_BUCKET = REACT_APP_AWS_EDITED_BUCKET + '/' + orderImagePath;
	// 		const REGION = REACT_APP_AWS_DEFAULT_REGION;
	// 		const https = require('https');
	// 		AWS.config.update({
	// 		  httpOptions: {
	// 			agent: new https.Agent({
	// 			  secureProtocol: 'TLSv1_2_method',
	// 			  ciphers: 'ALL',
	// 			}),
	// 		  },
	// 			accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
	// 			secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY
	// 		})
	// 		var myBucket = new AWS.S3({
	// 			params: { Bucket: S3_BUCKET},
	// 			region: REGION,
	// 			maxRetries: 20, // retry 10 times
    //             useAccelerateEndpoint: true
	// 			//retryDelayOptions: { base: 200 } // see AWS.Config for information
	// 		})
	// 	} catch (err) {
	// 		console.log(err);
	// 	}

	// 	this.uploadFiles(myBucket, S3_BUCKET);                   //  start the loop
    // }



	// saveImages_working_copy = async () => {
	// 	this.props.showLoaderCall(false);
	// 	// let arr = Array(10).fill(5);
	// 	// let counter = 1;
	// 	// for (let num of arr) {
	// 	// 	await this.props.getAgencyResourceByType('primary_logo',206)
	// 	// 	counter++;
	// 	// 	console.log(counter);
	// 	// }
	// 	// return;
	// 	const workingImages = [...this.state.workingImages];
    //     const files = [...this.state.workingImages];
    //     const orderImagePath = this.props.orderImagePath;
	// 	let toastIdImg = null;
	// 		toastIdImg =  toast(<React.Fragment>Images are Uploading. Please wait! {this.state.uploadedFiles +'/'+ workingImages.length}  <BiLoaderAlt className="loadingSpinner" /></React.Fragment>, {
	// 			progress: workingImages.length,
	// 			type: toast.TYPE.INFO,
	// 			position: "bottom-right",
    //             autoClose: true
	// 		});

    //     let responseArray = [];
    //     var currentImageName = '';

	// 	try{
	// 		const { REACT_APP_AWS_ACCESS_KEY_ID, REACT_APP_AWS_BUCKET, REACT_APP_AWS_DEFAULT_REGION, REACT_APP_AWS_SECRET_ACCESS_KEY  } = process.env;
	// 		var S3_BUCKET = REACT_APP_AWS_BUCKET + '/' + orderImagePath;
	// 		const REGION = REACT_APP_AWS_DEFAULT_REGION;
	// 		AWS.config.update({
	// 			accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
	// 			secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY
	// 		})
	// 		var myBucket = new AWS.S3({
	// 			params: { Bucket: S3_BUCKET},
	// 			region: REGION,
	// 		})
	// 	} catch (err) {
	// 		console.log(err);
	// 	}

	// 	for (const file of files) {

    //         let u_id = uuid().toString();
    //         let ext =  file.name.split('.').pop();
    //         currentImageName = u_id + '.' + ext;
    //         EXIF.getData(file, function() {
    //             const exifData = EXIF.pretty(this);
    //             let Data = {};
    //             if (exifData) {
    //                 console.log("u_id + '.' + ext===>", u_id + '.' + ext);
    //                 Data = {
    //                     file_size : file.size,
    //                     file_title : file.name,
    //                     file_type : file.type,
    //                     original_file_name : u_id + '.' + ext,
    //                     exif_orientation : EXIF.getTag(this, "Orientation") ? EXIF.getTag(this, "Orientation") : null,
    //                     use_in_highlight_video  : EXIF.getTag(this, "use_in_highlight_video") ? EXIF.getTag(this, "use_in_highlight_video") : 0,
    //                     exif_date_taken : EXIF.getTag(this, "DateTime") ? EXIF.getTag(this, "DateTime") : null,
    //                     DateTimeOriginal : EXIF.getTag(this, "DateTimeOriginal") ? EXIF.getTag(this, "DateTimeOriginal") : null,
    //                     ModifyDate : EXIF.getTag(this, "ModifyDate") ? EXIF.getTag(this, "ModifyDate") : null,
    //                     exif_manufacturer : EXIF.getTag(this, "Make") ? EXIF.getTag(this, "Make") : null,
    //                     exif_camera_model : EXIF.getTag(this, "Model") ? EXIF.getTag(this, "Model") : null,
    //                     exif_long   : EXIF.getTag(this, "GPSLongitude") ? EXIF.getTag(this, "GPSLongitude") : null,
    //                     exif_lat : EXIF.getTag(this, "GPSLatitude") ? EXIF.getTag(this, "GPSLatitude") : null,
    //                     height : EXIF.getTag(this, "PixelXDimension") ? EXIF.getTag(this, "PixelXDimension") : null,
    //                     width  : EXIF.getTag(this, "PixelYDimension") ? EXIF.getTag(this, "PixelYDimension") : null,
    //                 };
    //             } else {
    //                 Data = {
    //                     file_size : file.size,
    //                     file_title : file.name,
    //                     file_type : file.type,
    //                     original_file_name : u_id + '.' + ext
    //                 };
    //                 //   console.log("No EXIF data found in image '" + file.name + "'.");
    //             }

    //             responseArray.push(Data);

    //         });
    //         this.setState({
    //             imageData : responseArray,
    //         },() => {
    //             console.log("success", this.state.imageData)
    //         });
    //           try{

    //                 const params = {
    //                     ACL: 'public-read',
    //                     Body: file,
    //                     Bucket: S3_BUCKET,
    //                     Key: currentImageName
    //                 };

	// 				if(this.state.uploadedFiles % 25 === 0 && this.state.uploadedFiles != 0) {
	// 					await this.wait(5000);
	// 				}
	// 				await this.wait(500);

    //                 myBucket.putObject(params, (data)=>{

    //                    const uploaded = this.state.uploadedFiles;
    //                     this.setState({
    //                         uploadedFiles: uploaded + 1
    //                     },()=>{
    //                             if (this.state.uploadedFiles === workingImages.length) {
    //                                 toast.dismiss(toastIdImg);
    //                                 setTimeout(() => {


    //                                     const imageData = {images: this.state.imageData , uploaded_by_first_name: this.state.firstName, uploaded_by_last_name: this.state.lastName} ;
    //                                     this.props.storeOrderImagesInDBCall(imageData, this.props.orderId, 'working').then(

    //                                             response => {
    //                                                 this.setState({
    //                                                         workingImages: [],
    //                                                         uploadedFiles: 0,
    //                                                         collapseImageUploaderAndViewer: false,
    //                                                         firstName: '',
	// 		                                                lastName: '',
    //                                                     })
    //                                                     orderService.getCountOfImagesByFolder(this.props.orderId, 'working').then(response => {
    //                                                         this.setState({ workingImagesCount: response.data.count });
    //                                                     });
    //                                                 this.props.allOrderImagesByPhaseCall(this.props.orderId, 'working', this.props.tabName);
    //                                                 this.props.showLoaderCall(true);
    //                                             },
    //                                             error => {
    //                                                 console.log('error');
    //                                             }
    //                                             )

    //                                 }, 1000);
    //                             } else {
    //                                 toast.update(toastIdImg, {
    //                                     render: <React.Fragment>Images are Uploading. Please wait! {this.state.uploadedFiles +'/'+ workingImages.length}  <BiLoaderAlt className="loadingSpinner" /></React.Fragment>,
    //                                     progress: workingImages.length,
    //                                     type: toast.TYPE.INFO,
    //                                     position: "bottom-right",
    //                                 });
    //                             }
    //                     })

    //                }).then((response) => {console.log(response);});

    //         } catch (err) {
    //             Object.keys(err).map(x => {
    //                 file['message'] = err[x];
    //             });
    //         }
    //     }
    // }

	finalize = () => {
		const { orderId } = this.props;
		var settingData = {
			value: 1,
		};
		this.props.updateSettingsDataCall(orderId, 'finalize_by_editor', settingData).then(response => {
			this.props.getOrderCall(orderId);
			if (hasRole(['editor','Remote Editor','Remote Editor/Producer'])) {
				history.push(`dashboard`);
			} else if (hasRole('admin', 'gm admin')) {
				// console.log('admin role');
				history.push(`/Admin/dashboard/editor`);
			}

		});
	}
	assignProducerToOrder = (orderId) => {
		this.props.assignUserToOrderCall(orderId, { userId: this.props.userAdmin.user.id, role: 'producer' }).then(response => {
			if (hasRole(['admin', 'gm admin'])) {
				history.push(`/Admin/${orderId}/order-edit?orderDetailCode=production&imageCode=productionManager`);
				history.go();
				// const url = `/Admin/${orderId}/order-edit?orderDetailCode=production&imageCode=editor`;
				// window.open(url);
			} else {
				history.push(`/Admin/${orderId}/order-edit?imageCode=productionManager`);
				history.go();
				// const url = `/Admin/${orderId}/order-edit?imageCode=editor`;
				// window.open(url);
			}
		});
	}
	componentDidUpdate(prevProps, nextProps) {
		if (prevProps.imageUpdates !== this.props.imageUpdates) {
			this.setState({ workingImages: [] }, function () {
				// this.ctrl_list.imagesPaginationReload();
			});
		}
		if (this.props.order_details !== prevProps.order_details) {
			this.props.getAgencyResourceByType('animated_logo', this.props.order_details.agency_id).then(
				res => {
					if (res.data) {
						this.setState({
							animated_logo: res.data
						})
					}
				}
			);
		}
	}

	assignEditorToOrder = (orderId) => {
		this.props.assignUserToOrderCall(orderId, { userId: this.props.userAdmin.user.id, role: 'editor' }).then(response => {
			// if (hasRole(['admin'])) {
			// 	history.push(`/Admin/${orderId}/order-edit?orderDetailCode=production&imageCode=editor`);
			// 	history.go();

			//  	} else {
			//  		history.push(`/Admin/${orderId}/order-edit?imageCode=editor`);
			//    	history.go();

			//  	}
		});
	}

    loader = () => {
        const button = <button>Cancel</button>;
        return (
            <LoadingOverlay
                    active={this.props.allowSave}
                    className="overlayModify"
                    spinner={<PulseLoader />}
                    extraHtml={
                        <div>
                            <button type="button" className="">Canlce</button>
                        </div>}
                    text={<Button
                            cancelButton={this.cancelButton}
                            showCancelButton={this.state.showCancelButton}
                        />
                    }
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            size: '600px',
                            '& svg circle': {
                                stroke: 'rgba(192,192,192,0.8)'
                            }
                        }),
                        overlay: (base) => ({
                            ...base,
                            background: 'rgba(0,0,0,0.85)'
                        }),
                    }}
                ></LoadingOverlay>
        )
    }

	render() {
        // console.log("Render_imageData--->>>", this.state.imageData);
		const { orderId, /*tabName,*/ allImagesByPhase, order_details, userAdmin } = this.props;
		const { allowedFormatsStringExt, allowedFormatsStringMime, workingImages, workingImagesCount, orignalImagesCount, allowSave } = this.state;
		let imagesToDownload = [...allImagesByPhase.images]
		if (this.state.animated_logo.file_title) {
			imagesToDownload = [...imagesToDownload, this.state.animated_logo]
		}
		// const randomized = allImagesByPhase.images.filter(image => Number(image.randomized) === 1).length;
		// const positioned = allImagesByPhase.images.filter(image => (Number(image.randomized) === 0) && Number(image.sort_order) !== -1).length;

		return (
			<div>
                {
                    (allowSave) &&
                    this.loader()
                }
				{
					"finalize_by_editor" in order_details.settings && hasRole(['editor', 'gm admin', 'admin', 'Remote Editor','Remote Editor/Producer'])
					&&
					<Card bsClass={["innerCard"]} title="Upload Edited Photos" collapseTrigger={this.state.collapseImageUploaderAndViewer} collapsHandler={() => this.setState({ collapseImageUploaderAndViewer: !this.state.collapseImageUploaderAndViewer })} collapsable={true}
						content={

							<div>
								<div className="clearfix mb-md mt-xs text-right">
									{
										workingImages.length > 0 &&
										<span style={{ 'color': 'red' }} >Count of images to upload : {workingImages.length}</span>
									}

									{
										workingImages.length > 0 &&
										<button type="button" disabled={allowSave} className="orderDetailsAction finalizeIcon ml-md" onClick={this.confirmResourceDelete}>Save</button>
									}
									{
										workingImages.length > 0 &&
										<button type="button" disabled={allowSave} className="orderDetailsAction clearIcon ml-md" onClick={this.clearAllTempImages}>Clear ALL</button>
									}

								</div>
								{
									(
										(hasRole(['editor', 'gm admin', 'admin', 'Remote Editor','Remote Editor/Producer']) && (order_details.editor_id === userAdmin.user.id))
									) &&
										parseInt(order_details.settings.finalize_by_editor.value, 10) === 0
										?
										<React.Fragment>
											<span className="customUploader"></span>
											<FileUpload
												files={workingImages}
												setResourceFile={this.setResourceFile}
												updateResourceFile={this.updateResourceFile}
												allowedFormatsStringMimePro={allowedFormatsStringMime}
												allowedFormatsStringExtPro={allowedFormatsStringExt}
												askToUpdate={true}
												updateWithoutAsking={false}
												allowDuplicate={false}
												multiple={true}
											/>
										</React.Fragment>
										:
										TF.errorMsg("Can't upload now!", "danger", s3StaticPath('img/critical_icon.png'), " flex-center align-items-center")
								}
								<ImagePreview
									filesToPreview={workingImages}
									removeFile={this.removeFile}
								/>
							</div>
						}
					/>
				}
				<div className="clearfix mb-md"></div>
				<div className="col-md-6" >
					{
						order_details.originalImages > 0
						// Object.keys(allImagesByPhase.images).length > 0
							?
                            <a href={`${baseUrl}downloadImagesOriginal/${order_details.id}`} target="_blank" className="orderDetailsAction downloadIcon ml-sm mt-lg display_ib">Download ALL Original Images</a>
							// <DownloadFile
							// 	title="Download ALL Original Images"
							// 	multiple={true}
							// 	files={imagesToDownload}
							// 	downloadName={order_details.barcode}
							// />
							:
							TF.errorMsg("Don't have approved images to download", "info", s3StaticPath('img/critical_icon.png'), " flex-center align-items-center")
					}
                    {
                        order_details.is_fto ?
                        <span className="fto mt-lg right"><strong>&nbsp; FTO</strong></span>
                        :
                        ''
                    }
					<div className="table-responsive mt-xlg">
						<table className="table  table-striped">
							<tbody>
								<tr>
									<td>Destination</td>
									<td>{order_details.destinationText}</td>
								</tr>
								<tr>
									<td>Program Type</td>
									<td>{order_details.program_type.title}</td>
								</tr>
								<tr>
									<td>Depature Date</td>
									<td>{dateFormatMy(order_details.order_dates.departure_date.value)}</td>
								</tr>
								<tr>
									<td>Return Date</td>
									<td>{dateFormatMy(order_details.order_dates.return_date.value)}</td>
								</tr>
								<tr>
									<td>Special Need Date</td>
									<td>{dateFormatMy(order_details.order_dates.video_special_need_date.value)}</td>
								</tr>
							</tbody>
						</table>
					</div>
					{
						/*
							<div className="table-responsive mt-xlg">

								<table className="table  table-striped">
									<tbody>
										<tr>
											<td> Total Photos</td>
											<td>{ order_details.originalImages }</td>
										</tr>
										<tr>
											<td>Randomization</td>
											<td>{randomized > 0 ? randomized : 'No, images were randomized'}</td>
										</tr>
										<tr>
											<td>Positioning</td>
											<td>{positioned > 0 ? positioned : 'No, images were positioned'}</td>
										</tr>
									</tbody>
								</table>
							</div>
						*/
					}
					<div className="table-responsive mt-xlg">

						<table className="table  table-striped">
							<tbody>
								<tr>
									<td>Total Original Photos</td>

									<td>
										{order_details.originalImages}
										{
											/*
												<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">{ order_details.sortOrderTitle }</Tooltip>}>
													<span className="question_mark">?</span>
												</OverlayTrigger>
											*/
										}
										<OverlayTrigger placement="right" overlay={
											<Tooltip id="tooltip" className="static" >
												0001, 0002 if no sort order is applied <br />
												P_0001, P_0002 if images are custom positioned <br />
												R_0001, R_0002 if images are randomized <br />
											</Tooltip>
										}>
											<span className="question_mark">?</span>
										</OverlayTrigger>
									</td>
								</tr>


								<tr>
									<td>Randomization</td>
									<td>{order_details.sortOrder === 'Randomization' ? order_details.sortOrderCount : 'No images were randomized'}</td>
								</tr>
								<tr>
									<td>Positioning</td>
									<td>{order_details.sortOrder === 'Positioning' ? order_details.sortOrderCount : 'No images were positioned'}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="table-responsive mt-xlg">

						<table className="table table-striped " >
							<tbody>
								<tr>
									<td style={{ width: '50%' }}> Uploaded with App</td>
									<td>{order_details.appImagesCount}</td>
								</tr>
								<tr>
									<td style={{ width: '50%' }}>Uploaded with Website</td>
									<td>{order_details.webImagesCount}</td>
								</tr>
								<tr>
									<td style={{ width: '50%' }}>Audio/Video files uploaded</td>
									<td>{order_details.order_resources_audio_video_count}</td>
								</tr>
							</tbody>
						</table>

					</div>
				</div>

				<div className="col-md-6">
					<div className="mt-sm">
						{
							Number(order_details.settings.on_hold.value) !== 1 && workingImagesCount > 0
								?
								"finalize_by_editor" in order_details.settings &&
									(
										hasRole(['admin', 'gm admin']) ||
										(
											hasRole(['editor', 'Remote Editor','Remote Editor/Producer']) && (order_details.editor_id === userAdmin.user.id)
										)
									)
									?
									parseInt(order_details.settings.finalize_by_editor.value, 10) === 0
										?
										<button type="button" className="orderDetailsAction finalizeIcon ml-md" onClick={this.finalize} >Finalize</button>
										:
										order_details.settings.finalize_by_editor.updated_by_user != null &&
										<span style={{ 'fontSize': '10px', 'fontWeight': 'bold' }} >Finalized by {order_details.settings.finalize_by_editor.updated_by_user.name} and uploaded {order_details.workingImages}</span>
									:
									null
								:
								null
						}
						{
							order_details && order_details.workingImages > 0 &&
							<span style={{ 'color': 'red' }} >Total Uploaded Edited Photos: {order_details.workingImages}</span>
						}

						<br />
						{
							(order_details.editor && order_details.editor_id !== null) &&
							<span style={{ 'fontWeight': 'bold' }} >Editor : {order_details.editor.name}</span>
						}
						{
							Number(order_details.settings.finalize_by_editor.value) !== 1 &&
							this.props.userAdmin.user.id !== order_details.editor_id &&
							Number(order_details.settings.on_hold.value) !== 1 &&
							order_details.editor_id !== null &&
							hasRole(['admin', 'gm admin']) &&
							order_details.originalImages > 0 &&
							parseInt(order_details.settings.photos_renamed.value, 10) === 1 &&
							<React.Fragment>


								<OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{'Order assign me for editing process'}</Popover>}>
									<button style={{ 'fontSize': '10px' }} className="orderDetailsAction assignIcon ml-md" onClick={() => this.assignEditorToOrder(orderId)} >Assign Me For Editing Process</button>
								</OverlayTrigger>
							</React.Fragment>
						}

						{

							"finalize_by_editor" in order_details.settings && hasRole(['producer', 'gm admin', 'admin','Remote Producer','Remote Editor/Producer']) && order_details.producer_id === null
								?
								parseInt(order_details.settings.finalize_by_editor.value, 10) === 1
									?
									<OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{'Order assign me for production process'}</Popover>}>
										<button className="orderDetailsAction assignIcon ml-md" onClick={() => this.assignProducerToOrder(orderId)} >Assign Me For Production Process</button>
									</OverlayTrigger>
									:
									null
								:
								null

						}

					</div>
					<OrderComments showSteps={false} hideTitle={true} showOnly={['Production', 'TO_GL', 'TO_GL_Production']} addExceptTab={['TO_GL', 'All', 'TO_GL_Production']} modelId={order_details.id} order_details={order_details} />

					{
						/*

						<ImagesList
							provideCtrlListing={ctrlList => this.ctrl_list = ctrlList}
							orderId={orderId}
							versionType='working'
							tabName={tabName}
							extraHtml={<div className="mt-sm">


								{
									"finalize_by_editor" in order_details.settings && hasRole(['editor']) && order_details.editor_id === userAdmin.user.id
										?
										parseInt(order_details.settings.finalize_by_editor.value, 10) === 0
											?
											<button type="button" className="orderDetailsAction finalizeIcon ml-md" onClick={this.finalize} >Finalize</button>
											:
											<span>Finalized by editor</span>
										:
										null
								}

								{
									"finalize_by_editor" in order_details.settings && hasRole(['producer']) && order_details.producer_id === null
										?
										parseInt(order_details.settings.finalize_by_editor.value, 10) === 1
											?
											<OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{'Order assign me for production process'}</Popover>}>
												<button className="orderDetailsAction assignIcon ml-md" onClick={() => this.assignProducerToOrder(orderId)} >Assign Me</button>
											</OverlayTrigger>
											:
											null
										:
										null
								}



							</div>}
						/>
						*/
					}
				</div>


			</div>
		);
	};
}

function mapStateToProps(state) {
	const { loadingOrder, allImagesByPhase, imageUpdates, orderImagePath, allowSave } = state.ordersReducer;
	const { loading, userAdmin } = state.adminReducer;
	// console.log('order_details', order_details);
	return {
		loadingOrder, allImagesByPhase, imageUpdates, orderImagePath,
		loading, userAdmin, allowSave
	};
}

const mapDispatchToProps = dispatch => {
	return ({
		getOrderCall: (orderId) => { dispatch(getOrder(orderId)) },
        allowSaveCall: (flag) => { dispatch(allowSave(flag)) },
        disableSaveCall: (flag) => { dispatch(disableSave(flag)) },
		allOrderImagesByPhaseCall: (orderId, version_type, tabName) => { dispatch(allOrderImagesByPhase(orderId, version_type, tabName)) },
		storeEditorOrderImagesCall: (imagesData, orderId, folder) => { return dispatch(storeEditorOrderImages(imagesData, orderId, folder)) },
		updateSettingsDataCall: (model_id, key, settingData) => { return dispatch(updateSettingsData(model_id, key, settingData)) },
		assignUserToOrderCall: (orderId, data) => { return dispatch(assignUserToOrder(orderId, data)) },
		showLoaderCall: (status) => { dispatch(showLoader(status)) },
		getAgencyResourceByType: (title, agency_id) => { return dispatch(getAgencyResourceByType(title, agency_id)) },
		getOrderImagesPathCall: (orderId, folder) => { return dispatch(getOrderImagesPath(orderId, folder)) },
		storeOrderImagesInDBCall: (imagesData, orderId, folder) => { return dispatch(storeOrderImagesInDB(imagesData, orderId, folder)) },
	});
};

const Editor = connect(mapStateToProps, mapDispatchToProps)(EditorPage);
export default Editor;
